import { Card, CardHeader, Typography } from "@mui/material";
import React, { useContext, useMemo } from "react";
import { IMessage } from "@twin-shared";
import { ThemeContext } from "../../providers";
import { hexToRgba } from "@twin-shared";

interface ChatBubbleProps {
  message: IMessage;
  className?: string;
  id: string;
  sx?: any;
  IconComponent?: React.FC<React.SVGProps<SVGSVGElement>>;
  children: React.ReactNode;
  setRef?: (ref: any) => void;
}

export const ChatBubble = ({
  message,
  id,
  sx,
  children,
  setRef,
}: ChatBubbleProps) => {
  const { theme } = useContext(ThemeContext);
  const getColor = () =>
    useMemo(() => {
      if (message.sender === "user") {
        return hexToRgba(theme.colors.primary, theme.isDark ? 0.8 : 0.6)
      } else if (message.sender === "mimi") {
        return hexToRgba(theme.colors.background, theme.isDark ? 0.3 : 0.6)
      } else if(message.sender === "alert") {
        return hexToRgba(theme.colors.tertiary, theme.isDark ? 0.3 : 0.6);
      }
    }, [message]);

  return (
    <Card
      ref={(ref) => setRef && setRef(ref)}
      key={id}
      className={
        message.sender === "user" ? "wrapper-with-end" : "wrapper-with-start"
      }
      style={{
        backgroundColor: getColor(),
      }}
      sx={{ ...sx }}
      data-testid="chat-bubble-card"
    >
      <CardHeader
        component="div"
        title={
          <Typography
            component="div"
            sx={{
              whiteSpace: "pre-wrap",
              fontSize: "1rem",
              "@media (max-width: 600px)": {
                fontSize: "1rem",
              },
              "@media (max-width: 400px)": {
                fontSize: "0.8rem",
              },
              "@media (max-width: 300px)": {
                fontSize: "0.7rem",
              },
              "@media (max-width: 200px)": {
                fontSize: "0.6rem",
              },
            }}
          >
            {children}
          </Typography>
        }
      />
    </Card>
  );
};
