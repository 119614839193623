import { useContext, useEffect, useRef, useState } from "react";
import "react-h5-audio-player/lib/styles.css";
import AudioPlayer from "react-h5-audio-player"; 
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../app/store";
import { setActiveVideoId } from "../../features/message/messageSlice";
import { FiRotateCcw, FiRotateCw, FiPlayCircle, FiPauseCircle } from 'react-icons/fi';
import "./styles.css"; 
import { ThemeContext } from "../../providers";

 export const AudioPlayback = ({ component }: any) => {
  const rewindTime = component?.rewind_skip_seconds;
  const forwordTime = component?.rewind_skip_seconds;
  const playerRef = useRef(null);
  const containerRef = useRef(null);
  const dispatch = useDispatch(); 
  const { activeVideoId } = useSelector((state: RootState) => state.message);

   const { theme } = useContext(ThemeContext);
  
  useEffect(() => {
    if (component.id !== activeVideoId) {
      const audio = playerRef.current;
      if (audio) {
        if ((audio.paused || audio.ended) && audio.src) {
        } else if (!audio.paused) {
          try {
            playerRef?.current?.audio?.current.pause();
            return;
          } catch (e) {}
        }
      }
    }
  }, [playerRef, activeVideoId, component]);
  
  let url = component?.audio_url;
  url = url?.replace(
    "https://www.dropbox.com",
    "https://dl.dropboxusercontent.com"
  );
  url = url?.replace("dl=0", "raw=1");
  url = url?.replace("dl=1", "raw=1");
 

  
  return (<div ref={containerRef} key={component.id}>
      {url && (
      <div className="main-container" style={{ 
          backgroundColor: theme.isDark ? 'rgba(50,50,50,0.5)' :'rgba(0,0,0,0.7)'
        }}>
          <AudioPlayer
            loop={false}
            autoPlay={false}
            ref={playerRef}  
            src={url}
            onPlay={() => {
              dispatch(setActiveVideoId(component.id));
            }}
           
            onPause={() => {}}
            onError={(err: any) => {}}
            progressJumpStep={rewindTime * 1000}
            customIcons={{
              loop: (
                <div
                  style={{
                    visibility: "hidden",
                    width: 0,
                    height: 0,
                  }}
                />
              ),
              loopOff: (
                <div
                  style={{ display: "none",}}
                />
              ),
              play: (<div className="play-pause-container">
               <FiPlayCircle size={40} color="#fff" strokeWidth={1} /> 
                </div>
               ),
              pause: (<div className="play-pause-container"> <FiPauseCircle size={40} color="#fff" strokeWidth={1}  />
              </div> ),  
              rewind: (<div className="rewind-Container"> 
                <FiRotateCcw size={40} color="#fff" strokeWidth={1} />
                <p className="rewindText"  >
                {' '}{rewindTime}s
                </p>
              </div>),
              forward: (<div className="rewind-Container"> 
                 <FiRotateCw size={40} color="#fff" strokeWidth={1} /> 
                <p className="rewindText"  >
                {forwordTime}s{' '}
                </p>
              </div>),
            }}
          />
       </div>
    )}
  </div>
  );
};
