import React from "react";
import { Notification } from "../../components";
import { useSocketContext } from "../../providers";

export const NotificationList = () => {
  const { sendNotificationCallback } = useSocketContext();
  return (
    <Notification
      iconname="BellFilled"
      onActionClick={(action) =>
        sendNotificationCallback({ assistant_type: action })
      }
    />
  );
};
