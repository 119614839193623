import { notificationSlice } from './../features/notification/notificationSlice';
import { combineReducers, configureStore } from "@reduxjs/toolkit";
import { useDispatch } from "react-redux";
import { persistReducer, persistStore } from "redux-persist";
import storage from "redux-persist/lib/storage";
import message from "../features/message/messageSlice";
import chat from "../features/chat/chatSlice";
import app from "../features/app/appSlice";
import cartList from "../features/cartlist/cartlistSlice";
import notification from "../features/notification/notificationSlice";

const rootReducer = combineReducers({
  message,
  chat,
  app,
  cartList,
  notification
});

const persistConfig = {
  key: "root",
  storage,
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
  reducer: persistedReducer,
  devTools: process.env.NODE_ENV === "development",
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});

export const persistor = persistStore(store);

export type AppDispatch = typeof store.dispatch;

export type RootState = ReturnType<typeof store.getState>;

export const useAppDispatch = () => useDispatch<AppDispatch>();
