import { PUSH_NOTIFICATION_OFFLINE_STORAGE_KEY } from "@twin-shared/lib/constants";

const openDatabase: any = () => {
  return new Promise((resolve, reject) => {
    const request = indexedDB.open(PUSH_NOTIFICATION_OFFLINE_STORAGE_KEY, 2);
    request.onsuccess = () => resolve(request.result);
    request.onerror = (event: any) => reject(event.target.error);
  });
};

const getNotifications = async () => {
  const db = await openDatabase();

  if (!db.objectStoreNames.contains("messages")) {
    return [];
  }

  const tx = db.transaction("messages", "readonly");
  const store = tx.objectStore("messages");
  return new Promise((resolve, reject) => {
    const request = store.getAll();
    request.onsuccess = () => resolve(request.result);
    request.onerror = (event) => reject(event.target.error);
  });
};

const clearNotifications = async () => {
  const db = await openDatabase();

  if (!db.objectStoreNames.contains("messages")) {
    return;
  }

  const tx = db.transaction("messages", "readwrite");
  const store = tx.objectStore("messages");

  return new Promise((resolve, reject) => {
    tx.oncomplete = function () {
      console.log(`All data cleared`);
      resolve(tx.result);
    };

    tx.onerror = function (event) {
      console.error(`Error clearing data: ${event.target.error}`);
      reject(event.target.error);
    };

    // Loop through all object stores and clear their data
    Array.from(db.objectStoreNames).forEach((storeName) => {
      const objectStore = tx.objectStore(storeName);
      objectStore.clear();
      console.log(`Cleared data from store: ${storeName}`);
    });
  });
};

export { getNotifications, openDatabase, clearNotifications };
