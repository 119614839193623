import { Button } from "antd";
import { useState } from "react";

export const ListExtenderView = ({
  component,
  render,
}: {
  component: any;
  render: (c: any) => any;
}) => {
  if (!component?.children || component.type !== "wrapper:list_extender")
    return null;

  const { batch_size: batchSize, content } = component;
  const data = content?.children || [];
  const [visibleCount, setVisibleCount] = useState(batchSize);

  const visibleData = data.slice(0, visibleCount);

  const canShowMore = visibleCount < data.length;
  const canShowLess = visibleCount > batchSize;

  const handleShowMore = () => {
    setVisibleCount((prevCount) =>
      Math.min(prevCount + batchSize, data.length)
    );
  };

  const handleShowLess = () => {
    setVisibleCount((prevCount) => Math.max(prevCount - batchSize, batchSize));
  };

  const renderButtons = () => (
    <div style={{ flexDirection: "row" }}>
      <Button
        disabled={!canShowMore}
        onClick={handleShowMore}
        className="rounded-[5px] max-[28px] h-[27px]"
        style={{
          backgroundColor: 'white',
          color: 'black',
          opacity: canShowMore ?  1: 0.6, 
        }}
      >
        Show More
      </Button>
      <Button
        disabled={!canShowLess}
        onClick={handleShowLess}
        className="rounded-[5px] max-[28px] h-[27px] m-[5px]"
        style={{
          backgroundColor: 'white',
          color: 'black',
          opacity: canShowLess ?  1:0.6,
         }}
      >
        Show Less
      </Button>
    </div>
  );

  return (
    <div>
      <div>{render({ ...content, children: visibleData })}</div> 
      {renderButtons()}
    </div>
  );
};
