import { createSlice } from "@reduxjs/toolkit";

export interface NotificationState {
  unreadCount: number;
  list: any[];
}

const initialState: NotificationState = {
  unreadCount: 0,
  list: [],
};

export const notificationSlice = createSlice({
  name: "notification",
  initialState,
  reducers: {
    addNotification: (state, action) => {
      state.list = [...state.list, action.payload];
      state.unreadCount = state.list.filter((a) => !a.markAsRead).length;
    },
    markAsRead: (state, action) => {
      state.list = state.list.map((item) => {
        if (item.id === action.payload) {
          item.markAsRead = true;
        }
        return item;
      });
      state.unreadCount = state.list.filter((item) => !item.markAsRead).length;
    },
    setExpand: (state, action) => {
      state.list = state.list.map((item) => {
        if (item.id === action.payload) {
          item.expand = !item.expand;
        }
        return item;
      });
    },
    removeNotification: (state, action) => {
      state.list = state.list.filter((msg) => msg.id !== action.payload);
    },
  },
});

export const { addNotification, markAsRead, removeNotification, setExpand } =
  notificationSlice.actions;
export default notificationSlice.reducer;
